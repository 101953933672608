
import React, { useContext, Children } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useParams } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'
import YellowAlert from '../ui-elements/YellowAlert'



type Props = {
  fridgeId?: string
  titleText?: string
  instructionsText?: string
  subheadingText?: string | null
  fridgeHasDigitalDisplay?: boolean | null
}

function HeaderDetails({
  titleText,
  instructionsText,
  subheadingText,
  fridgeId,
  fridgeHasDigitalDisplay
}: Props) {

  const {
    tableData,
    isVideoMode
  } = useContext(GlobalContext)
  const { questionId, checklistId } = useParams()

  return <div className={`
  ${isVideoMode ? ' fixed w-full max-w-3xl px-5 ' : ' w-full '}`}>

    <div className={`
    ${isVideoMode ? ' mt-4 rounded ' : ' border-b-2 border-gray-300 '}
    w-full flex flex-row gap-4 items-center justify-center 
    `}
      style={{
        background: `rgba(255, 255, 255, ${isVideoMode ? '0.8' : '1'})`
      }}
    >
      <div className={`max-w-3xl w-full px-5 py-2 flex flex-col gap-0 items-center justify-center text-center`}>

        {titleText && <h3 className={`font-righteous text-3xl font-brandblue`}>{titleText}</h3>}

        {fridgeId && <h3 className={`font-righteous text-lg font-brandblue`}>
          Temperature check for fridge:
          {tableData && tableData.Assets && tableData.Assets[fridgeId] ?
            ` ${tableData.Assets[fridgeId]['AssetName']} (#${fridgeId}) ` :
            ` #${fridgeId} `
          }
        </h3>}



        {fridgeId && <p className={`text-sm italic text-gray-600`}>
          Scan the QR code for fridge ID: <span className={`font-bold`}>#{fridgeId}</span>.
          </p>}


        {subheadingText && <p className={`mt-2 w-full text-sm text-gray-600 italic`}>
          {subheadingText}</p>}



        {instructionsText && <p className={`mt-2 w-full text-sm text-gray-600 italic`}>
          <span className={`font-bold`}>Instructions:</span> {instructionsText}
        </p>}

        {/* {instructionsText && <div className={`mt-2 w-full`}><YellowAlert size='small' icon={false}>{instructionsText}</YellowAlert></div>} */}
      </div>
    </div>
  </div>
}
export default HeaderDetails