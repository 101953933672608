import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'
import { useVideo } from '../context/video/VideoContext'
import Card from '../ui-elements/Card'
import Modal from '../ui-elements/Modal'
import Text from '../formfields-new/Text'
import Number from '../formfields-new/Number'
import Spinner from '../ui-elements/Spinner'
import { validateForm } from '../utils/validateForm'
import XButton from '../formfields/XButton'
import FormatFridgeName from '../dashboard/FormatFridgeName'



type Props = {
  jpegString: string | null
  setShowReadingDetectedModal: React.Dispatch<React.SetStateAction<any>>
  // detectedTemperatureReading: number | string | null
  questionAnswer: string | null | string[]
  setQuestionAnswer: React.Dispatch<React.SetStateAction<any>>
  answerNote: string | null
  setAnswerNote: React.Dispatch<React.SetStateAction<string | null>>
  answerPhoto: string | null
  setAnswerPhoto: React.Dispatch<React.SetStateAction<string | null>>
  digitalDisplay: boolean
  retakePhoto: AnyFunction
  aiResults: ObjectStringKeyAnyValue
  fridgeId: string | null
  isAnswerManuallyEdited: boolean
  setIsAnswerManuallyEdited: React.Dispatch<React.SetStateAction<any>>
}

function FridgeReadingDetected({
  jpegString,
  setShowReadingDetectedModal,
  // detectedTemperatureReading: detectedTemperatureReading,
  questionAnswer,
  setQuestionAnswer,
  answerNote,
  setAnswerNote,
  answerPhoto,
  setAnswerPhoto,
  digitalDisplay,
  retakePhoto,
  aiResults,
  fridgeId,
  isAnswerManuallyEdited,
  setIsAnswerManuallyEdited
}: Props) {


  const {
    tableData
  } = useContext(GlobalContext)

  const { captureAsJPEGString, straightenedImageUrl, straightenedImageNoQRUrl } = useVideo()
  const [addNote, setAddNote] = useState(false)
  const [readingFromAi, setReadingFromAi] = useState<string | null>(null)
  const [imageFromAi, setImageFromAi] = useState<string | null>(null)


  useEffect(() => {
    if (fridgeId && aiResults[fridgeId]) {
      if (digitalDisplay && aiResults[fridgeId].reading) {
        setReadingFromAi(aiResults[fridgeId].reading)
        setQuestionAnswer(aiResults[fridgeId].reading)
        setIsAnswerManuallyEdited(false)
      }
      aiResults[fridgeId].uncroppedJpeg && setImageFromAi(aiResults[fridgeId].uncroppedJpeg)
    }
  }, [aiResults, fridgeId, digitalDisplay])




  return <div className={`w-full flex flex-col items-center justify-center `}>
    <div className={`relative border border border-gray-200 p-4 bg-white rounded shadow`}>

      {/* {digitalDisplay && <div className={`absolute right-0 top-0 m-1`}>
        <XButton clickFunction={retakePhoto} />
      </div>} */}

      <div className={`py-2 w-full flex flex-col gap-2 items-center text-center`}>



        {imageFromAi && <h4 className={`font-righteous text-brandblue text-xl`}>
          Image captured for
          <FormatFridgeName fridgeId={fridgeId} />
        </h4>}

        <div className={`relative flex flex-col min-h-24 max-h-36 min-w-24 rounded bg-gray-300 w-full`}>

          {imageFromAi && <img src={imageFromAi} className={`rounded min-h-24 max-h-36 bg-gray-400`} alt={''} />}
          {!readingFromAi && digitalDisplay && <div className={`absolute h-full w-full top-0 p-2 flex flex-col items-center justify-center`}>
            <div className={`bg-white rounded p-1 opacity-60`}>
              <Spinner size={'small'}>
                <p className={`text-xs`}>Detecting reading...</p>
              </Spinner>
            </div>
          </div>}

          {!imageFromAi && !digitalDisplay && <div className={`absolute h-full w-full top-0 p-2 flex flex-col items-center justify-center`}>
            <div className={`bg-white rounded p-1 opacity-60`}>
              <Spinner size={'small'}>
                <p className={`text-xs`}>Capturing image...</p>
              </Spinner>
            </div>
          </div>}


        </div>

        <div className={`w-full flex flex-col gap-2`}>
          <div className={`w-full flex flex-col gap-2 items-center justify-center`}>
            <p> {readingFromAi && digitalDisplay ?
              'Edit reading if required:' :
              'Enter reading:'
            }</p>
            <input
              className={`text-left font-righteous text-brandblue text-3xl bg-white text-center drop-shadow-lg border border-gray-300 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-5 py-3`}
              onChange={(e) => {
                setQuestionAnswer(e.target.value)
                setIsAnswerManuallyEdited(true)
              }}
              type={`number`}
              value={questionAnswer || ''}
            />
          </div>

        </div>
      </div>
    </div>
  </div>
}
export default FridgeReadingDetected