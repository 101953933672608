
import React, { useContext, Children, useState, useEffect } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'
import FooterNavButtons from '../shared-components/FooterNavButtons'
import HeaderDetails from '../shared-components/HeaderDetails'
import RedAlert from '../ui-elements/RedAlert'
import Modal from '../ui-elements/Modal'
import { subscribe } from '../utils/pubsub'
import { useVideo } from '../context/video/VideoContext'

// question types
import QuestionYesOrNo from './QuestionYesOrNo'
import QuestionText from './QuestionText'
import QuestionFridgeTemperature from './QuestionFridgeTemperature'
import QuestionPhoto from './QuestionPhoto'
import QuestionSelectMultiple from './QuestionSelectMultiple'
import QuestionSelectOne from './QuestionSelectOne'
import FormatQuestionAnswer from '../dashboard/FormatQuestionAnswer'
import { b64toBlob } from '../utils/b64toBlob'
import QuestionConfirmPage from './QuestionConfirmPage'
import { findNextQuestionUrl } from '../utils/findNextQuestionUrl'
import { getChecklistProgress } from '../utils/getChecklistProgress'
import { sortAllQuestionAnswersForCheckPeriod } from '../utils/sortAllQuestionAnswersForCheckPeriod'
import { checkIfAnswerIsLocked } from '../utils/checkIfAnswerIsLocked'
import Card from '../ui-elements/Card'
import QuestionNumber from './QuestionNumber'
import QuestionMultipleTextFields from './QuestionMultipleTextFields'



type Props = {
  showAnswerConfirmation: boolean
  children?: any
}

function QuestionPage({
  showAnswerConfirmation = false,
  children
}: Props) {

  const {
    tableData,
    setShowModal,
    sendMessageToWebsocket,
    isVideoMode,
    setIsVideoMode,
    setIsVideoPlaying,
    loggedIn,
    userData,
    isSignoffRequired,
    checkPeriodIdsForActiveCheckists
  } = useContext(GlobalContext)

  const navigate = useNavigate()
  const { answerId, checkPeriodId } = useParams()
  const [isQuestionLocked, setIsQuestionLocked] = useState(false)
  const [lockedUntilText, setLockedUntilText] = useState('')
  const [isAnswerPending, setIsAnswerPending] = useState(false)
  const [thisQuestion, setThisQuestion] = useState<null | ObjectStringKeyAnyValue>(null)
  const [questionAnswerValue, setQuestionAnswerValue] = useState<null | string | ObjectStringKeyAnyValue | Array<string>>(null)
  const [questionAnswer, setQuestionAnswer] = useState<null | ObjectStringKeyAnyValue>(null)
  const [answerNote, setAnswerNote] = useState<null | string>(null)
  const [answerPhoto, setAnswerPhoto] = useState<null | string>(null)
  const [subheadingText, setSubheadingText] = useState<string>('')
  const [isAnswerManuallyEdited, setIsAnswerManuallyEdited] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const [nextButtonText, setNextButtonText] = useState('')
  const [nextButtonAction, setNextButtonAction] = useState<null | AnyFunction>(null)
  const [nextStepUrl, setNextStepUrl] = useState<null | string>(null)
  const [checklistProgress, setChecklistProgress] = useState<null | ObjectStringKeyAnyValue>(null)
  const [jpegString, setJpegString] = useState<string | null>(null)
  const { captureAsJPEGString, straightenedImageUrl, straightenedImageNoQRUrl, QRCodesPresent,
    setQRCodesPresent, clearCameraStateVariables } = useVideo()
  const [fridgeHasDigitalDisplay, setFridgeHasDigitalDisplay] = useState<null | boolean>(null)

  const checklistId = checkPeriodId && tableData && tableData.CheckPeriods && tableData.CheckPeriods[checkPeriodId] && tableData.CheckPeriods[checkPeriodId]['ChecklistId'] ? tableData.CheckPeriods[checkPeriodId]['ChecklistId'] : null

  const thisCheckPeriodSignoff = checkPeriodId && tableData && tableData.CheckPeriods && tableData.CheckPeriods[checkPeriodId] && tableData.CheckPeriods[checkPeriodId]['Signoff'] ? tableData.CheckPeriods[checkPeriodId]['Signoff'] : null

  // console.log(`🪼`)

  useEffect(() => {
    if (questionAnswer) {
      checkIfAnswerIsLocked(questionAnswer, setIsQuestionLocked, setLockedUntilText)
      const intervalId = setInterval(() => {
        checkIfAnswerIsLocked(questionAnswer, setIsQuestionLocked, setLockedUntilText)
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }, [questionAnswer])




  useEffect(() => {
    handleSetThisQuestion()

    if (tableData && checkPeriodId) {
      const progress = getChecklistProgress(checkPeriodId, tableData)
      if (progress) {
        setChecklistProgress(progress)
      }
    }

    if (thisQuestion && checkPeriodId && tableData) {
      const nextStep = findNextQuestionUrl(answerId, checkPeriodId, tableData, '')
      nextStep && setNextStepUrl(nextStep)
    }


  }, [tableData, answerId, thisQuestion, checkPeriodId])



  useEffect(() => {
    // clear error message when answer gets updated
    setErrorMessage(null)
  }, [questionAnswerValue])



  const handleSetThisQuestion = () => {
    if (
      answerId &&
      tableData &&
      tableData.ChecklistQuestions &&
      tableData.ChecklistAnswers
    ) {

      if (tableData.ChecklistAnswers[answerId]) {
        let subtitleText = ''
        const questionAnswerObject = tableData.ChecklistAnswers[answerId]
        setQuestionAnswer(questionAnswerObject)
        setQuestionAnswerValue(questionAnswerObject.Answer)
        setIsAnswerPending(questionAnswerObject.Pending)
        const questionIdForThisAnswer = questionAnswerObject.QuestionId
        if (tableData.ChecklistQuestions[questionIdForThisAnswer]) {
          const questionForThisAnswer = tableData.ChecklistQuestions[questionIdForThisAnswer]
          setThisQuestion(questionForThisAnswer)

          // if it's a fridge temperature question, check if it's got a digital display
          if (questionForThisAnswer.QuestionFormat === 'FridgeTemperature' && questionForThisAnswer.FridgeId) {
            if (
              tableData.Assets &&
              tableData.Assets[questionForThisAnswer.FridgeId] &&
              tableData.Assets[questionForThisAnswer.FridgeId].HasDigitalReadout === true
            ) {
              //console.log(tableData.Assets[questionForThisAnswer.FridgeId])
              setFridgeHasDigitalDisplay(true)
            } else {
              setFridgeHasDigitalDisplay(false)
            }
          }

          // set the subheading text if its a follow up
          if (questionForThisAnswer.parentQuestionId && tableData.ChecklistQuestions[questionForThisAnswer.parentQuestionId]) {
            const parentQuestionText = tableData.ChecklistQuestions[questionForThisAnswer.parentQuestionId].QuestionText
            subtitleText = `${subtitleText} Follow up question for: ${parentQuestionText ? `"${parentQuestionText}"` : `ID #:${questionForThisAnswer.parentQuestionId}`}`
          }
        }
        // set the subheading text if its a repeated
        if (questionAnswerObject.repeatAnswerAfter && questionAnswerObject.repeatAnswerAfter.type === 'numberOfTimes') {
          subtitleText = `${subtitleText} 🔁 Repeated after ${questionAnswerObject.repeatAnswerAfter.number} time${questionAnswerObject.repeatAnswerAfter.number > 1 ? 's' : ''}`
        }
        // set the subheading text if its a delayed question
        if (questionAnswerObject.repeatAnswerAfter && questionAnswerObject.repeatAnswerAfter.type === 'timeDelay') {
          subtitleText = `${subtitleText} 🔁 Repeated after ${questionAnswerObject.repeatAnswerAfter.number < 60 ? ` ${questionAnswerObject.repeatAnswerAfter.number} minutes ` : ` ${Math.round(questionAnswerObject.repeatAnswerAfter.number / 60 * 10) / 10} hours `}`
        }
        setSubheadingText(subtitleText)
      }
    }
  }


  const handleChangeSelectMultiple = (value: string) => {
    let newAnswerArray: string[]

    // if existing questionAnswer is not an array, start a new array
    if (questionAnswerValue && questionAnswerValue instanceof Array) {
      newAnswerArray = [...questionAnswerValue]
    } else {
      newAnswerArray = []
    }
    // add the value if it doesn't already exist
    // remove the value if it does exist already
    if (newAnswerArray.includes(value)) {
      newAnswerArray = newAnswerArray.filter((option) => option !== value)
    } else {
      newAnswerArray.push(value)
    }
    setQuestionAnswerValue(newAnswerArray)
  }


  const handleChangeMultiText = (key: string, value: string) => {

    let newAnswerObject: ObjectStringKeyAnyValue = {}
    if (questionAnswerValue && questionAnswerValue instanceof Object) {
      newAnswerObject = { ...questionAnswerValue }
    }
    if (value) {
      newAnswerObject[key] = value
    } else {
      delete newAnswerObject[key]
    }


    setQuestionAnswerValue(newAnswerObject)
  }


  const validate = async (behaviour: any) => {
    if (!questionAnswerValue && thisQuestion?.QuestionFormat !== 'Photo') {
      setErrorMessage('Please select or enter a valid answer')
    }
    else if (
      thisQuestion?.QuestionFormat === 'MultipleTextFields' &&
      (!questionAnswerValue ||
        Object.keys(questionAnswerValue).length === 0 ||
        Object.keys(questionAnswerValue).length < thisQuestion.QuestionFieldNames.length
      )
    ) {
      setErrorMessage('Please enter an answer for each field')
    }
    else if (thisQuestion?.QuestionFormat === 'Photo' && !answerPhoto) {
      setErrorMessage('Please take a picture')
    } else {
      await saveAnswer(behaviour)
    }
  }

  const saveNote = async () => {
    console.log(`saving note "${answerNote}" for answer id: ${answerId}`)
    if (answerId && answerNote) {
      setShowModal({ "spinner": 'Saving...' })
      const payload: ObjectStringKeyAnyValue = {
        action: "checklistsClientApp",
        subAction: "saveAnswerNote",
        answerId: `${answerId}`,
        answerNote: answerNote
      }
      // if a user is logged in as a guest, add their name to the data
      if (loggedIn === 'guest') {
        payload['GuestUserName'] = userData.name
      }
      sendMessageToWebsocket(JSON.stringify(payload))
      const unsubscribe = subscribe("tableUpdateItemModified", data => {
        if (data.newRecord && data.newRecord.Id === answerId) {
          setShowModal(null)
          setAnswerNote(null)
          unsubscribe()
        }
      })
    }
  }

  const saveAnswer = async (behaviour: any) => {
    setErrorMessage(null)
    if (answerId) {
      setQRCodesPresent(null)
      setShowModal({ "spinner": 'Saving...' })

      // send answer to websocket
      const payload: ObjectStringKeyAnyValue = {
        action: "checklistsClientApp",
        subAction: "saveAnswerToQuestion",
        answerId: `${answerId}`,
        values: {
          Answer: questionAnswerValue
        }
      }
      // add an answer note if there is one
      if (answerNote) payload.values['AnswerNote'] = answerNote

      // if a user is logged in as a guest, add their name to the data
      if (loggedIn === 'guest') {
        payload['GuestUserName'] = userData.name
      }

      // if its a fridge temp, include if it's manually edited 
      if (thisQuestion?.QuestionFormat === 'FridgeTemperature') {
        payload.values['isAnswerManuallyEdited'] = isAnswerManuallyEdited
      }

      // console.log(payload)
      sendMessageToWebsocket(JSON.stringify(payload))

      // navigate once answer is saved
      const unsubscribe = subscribe("answerUpdateComplete", data => {
        if (data.answerId === answerId) {
          !answerPhoto && setShowModal(null)
          setJpegString(null)
          setQuestionAnswerValue(null)
          setAnswerNote(null)
          const nextUrl = (findNextQuestionUrl(answerId, checkPeriodId, tableData, behaviour))
          //console.log(`go to: ${nextUrl}`)
          navigate(nextUrl)
          unsubscribe()
        }
      })

      // if there is a photo, upload it
      if (answerPhoto) {
        const timestamp = `${Date.now()}`
        let fileName = `${answerId}-${timestamp}.jpg`
        await handleImageUpload(answerId, answerPhoto, fileName, timestamp, behaviour)
      }

    } else {
      setErrorMessage('Sorry - could not save this answer')
    }
  }


  const handleImageUpload = async (answerId: string, dataString: string, fileName: string, timestamp: string, behaviour: any) => {
    const payload = JSON.stringify({
      action: "checklistsClientApp",
      subAction: "requestPresignedUrl",
      fileName: fileName,
      waitingForUploadUrlId: timestamp
    })
    sendMessageToWebsocket(payload)
    const response = await new Promise<void>((resolve, reject) => {
      const unsubscribe = subscribe("returnPresignedUrl", async data => {
        if (data.waitingForUploadUrlId === timestamp) {
          unsubscribe()
          const prefix = 'data:image/jpeg;base64,'
          const fileBody = dataString!.split(prefix)[1]
          const file = new File([b64toBlob(fileBody)], timestamp, { type: "image/jpeg" })
          await fetch(data.signedUrl, {
            method: 'PUT',
            body: file
          })
            .then(response => {
              // send answer to websocket
              const payload = JSON.stringify({
                action: "checklistsClientApp",
                subAction: "saveAnswerToQuestion",
                answerId: `${answerId}`,
                values: {
                  ImageFileName: data.imageFileName
                }
              })
              sendMessageToWebsocket(payload)

              // navigate once answer is saved
              const unsubscribe = subscribe("tableUpdateItemModified", data => {
                //console.log(`🥬 ${JSON.stringify(data)}`)
                if (data.newRecord && data.newRecord.Id && data.newRecord.Id === answerId) {
                  setShowModal(null)
                  setJpegString(null)
                  setAnswerPhoto(null)
                  const nextUrl = (findNextQuestionUrl(answerId, checkPeriodId, tableData, behaviour))
                  //console.log(`navigate to: ${nextUrl}`)
                  navigate(nextUrl)
                  unsubscribe()
                }
              })

              return (response)
            }).catch((error) => {
              console.error('Error:', error)
            })
        }
      })
    })
  }

  const retakePhoto = () => {
    setAnswerPhoto && setAnswerPhoto(null)
    setJpegString(null)
    setIsVideoPlaying(true)
    setIsVideoMode(true)
    setQRCodesPresent(null)
    clearCameraStateVariables()
    setQuestionAnswerValue(null)
  }


  const capturePhoto = () => {
    const jpgData = captureAsJPEGString(600)
    setAnswerPhoto && setAnswerPhoto(jpgData || null)
    setJpegString && setJpegString(jpgData || null)
    setIsVideoPlaying(false)
    setIsVideoMode(false)
  }



  const resetQuestion = (answerId: string) => {
    setShowModal({ "spinner": 'Saving...' })
    const payload = JSON.stringify({
      action: "checklistsClientApp",
      subAction: "resetAnswer",
      answerId: answerId
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("tableUpdateItemModified", data => {
      //console.log(`🪼 ${JSON.stringify(data)}`)
      if (data.newRecord && data.newRecord.Id && data.newRecord.Id === answerId) {
        setShowModal(null)
        navigate(`/checklists/${checkPeriodId}`)
        unsubscribe()
      }
    })
  }


  // console.log(questionAnswer)


  return <div
    className={`w-full flex-1 h-full flex flex-col justify-between gap-2`}
  >


    <div className={`w-full flex flex-col items-center`}>
      {thisQuestion && thisQuestion.QuestionFormat !== 'FridgeTemperature' &&
        <HeaderDetails
          titleText={` ${thisQuestion ? thisQuestion.QuestionText : 'Unknown Question'}`}
          instructionsText={thisQuestion.QuestionInstructions}
          subheadingText={subheadingText}
        />}


      {thisQuestion && thisQuestion.QuestionFormat === 'FridgeTemperature' && <HeaderDetails
        fridgeId={thisQuestion.FridgeId}
        instructionsText={thisQuestion.QuestionInstructions}
        fridgeHasDigitalDisplay={fridgeHasDigitalDisplay}
      />}



      {!tableData && <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}><Spinner><p>Loading...</p></Spinner></div>}

      {tableData && <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}>




        {!thisQuestion && <div>
          <RedAlert>Invalid question</RedAlert>
        </div>}


        {thisQuestion && !isAnswerPending && showAnswerConfirmation &&
          <QuestionConfirmPage
            question={thisQuestion}
            answerId={answerId}
            answerNote={answerNote}
            setAnswerNote={setAnswerNote}
            saveNote={saveNote}
            setJpegString={setJpegString}
            resetQuestion={resetQuestion}
            setAnswerPhoto={setAnswerPhoto}
          />}


        {isQuestionLocked && thisQuestion && <Card>
          <div className={`flex flex-col items-center`}>
            <p className={`text-4xl`}>🔐</p>
            <h5 className={`font-righteous text-brandblue text-lg`}>This question is locked</h5>
            <p className={``}>{lockedUntilText}</p>
          </div>
        </Card>}

        {!isQuestionLocked && thisQuestion && !showAnswerConfirmation &&
          <div className={`w-full mb-4`}>

            {thisQuestion.QuestionFormat === 'YesOrNo' && <QuestionYesOrNo
              setQuestionAnswer={setQuestionAnswerValue}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
            />}

            {thisQuestion.QuestionFormat === 'Text' && <QuestionText
              setQuestionAnswer={setQuestionAnswerValue}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
            />}

            {thisQuestion.QuestionFormat === 'MultipleTextFields' && <QuestionMultipleTextFields
              setQuestionAnswer={setQuestionAnswerValue}
              question={thisQuestion}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              handleChangeMultiText={handleChangeMultiText}
              defaultValue={questionAnswerValue}
            />}

            {thisQuestion.QuestionFormat === 'Number' && <QuestionNumber
              setQuestionAnswer={setQuestionAnswerValue}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
            />}

            {thisQuestion.QuestionFormat === 'Photo' && <QuestionPhoto
              answerPhoto={answerPhoto}
              setAnswerPhoto={setAnswerPhoto}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              answerNote={answerNote}
              setAnswerNote={setAnswerNote}
              defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
              jpegString={jpegString}
              setJpegString={setJpegString}
            />}


            {
              thisQuestion.QuestionFormat === 'FridgeTemperature' &&
              <QuestionFridgeTemperature
                questionId={thisQuestion.Id}
                checklistId={checklistId}
                defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
                fridgeId={thisQuestion.FridgeId}
                questionAnswer={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
                setQuestionAnswer={setQuestionAnswerValue}
                answerNote={answerNote}
                setAnswerNote={setAnswerNote}
                answerPhoto={answerPhoto}
                setAnswerPhoto={setAnswerPhoto}
                isAnswerManuallyEdited={isAnswerManuallyEdited}
                setIsAnswerManuallyEdited={setIsAnswerManuallyEdited}
                jpegString={jpegString}
                setJpegString={setJpegString}
                fridgeHasDigitalDisplay={fridgeHasDigitalDisplay}
                checkPeriodId={checkPeriodId}
              />}

 

            {thisQuestion.QuestionFormat === 'SelectMultiple' && <QuestionSelectMultiple
              handleChangeSelectMultiple={handleChangeSelectMultiple}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerValue !== null && questionAnswerValue instanceof Array ? questionAnswerValue : ''}
              questionOptions={thisQuestion.QuestionOptions}
            />}

            {thisQuestion.QuestionFormat === 'SelectOne' && <QuestionSelectOne
              setQuestionAnswer={setQuestionAnswerValue}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
              questionOptions={thisQuestion.QuestionOptions}
            />}


          </div>}

        {errorMessage && <Modal
        // showCloseButton={true}
        // setShowModal={setErrorMessage}
        // closeButtonValue={null}
        >
          <div className={`flex flex-col gap-4`}>
            <RedAlert icon={true} size={'large'}>{errorMessage}</RedAlert>
            <Button
              onClick={() => setErrorMessage(null)}
              text={'Ok'}
              size={'big'}
            /></div>
        </Modal>}

      </div>}
    </div>


 

    <div className={`
  ${isVideoMode ? '' : ' border-t-2 border-gray-300 bg-white '} 
  fixed bottom-0 w-full flex flex-col items-center justify-center `}>

      {/* <p className={`text-xs text-center text-gray-300`}>{findNextQuestionUrl(answerId, checkPeriodId, tableData, '')}</p> */}



      <div className={`max-w-3xl w-full px-5 py-3 flex flex-row gap-2`}>


        {!(jpegString && isAnswerPending) && <Button
          internalLinkUrl={`/checklists/${checkPeriodId}/`}
          text={`Back`}
          disabled={false}
          variant={'primary'}
        // size={'big'}
        />}


        {checkPeriodId && !answerPhoto && isAnswerPending && thisQuestion?.QuestionFormat === 'Photo' && <Button
          onClick={capturePhoto}
          text={`📸 Take photo`}
          disabled={false}
          variant={'primary'}
        // size={'big'}
        />}

        {jpegString && isAnswerPending && <Button
          onClick={retakePhoto}
          text={`Retake`}
          disabled={false}
          variant={'primary'}
        // size={'big'}
        />}

        {
          isAnswerPending &&
          !showAnswerConfirmation &&
          !isQuestionLocked &&
          (thisQuestion?.QuestionFormat !== 'Photo' || thisQuestion?.QuestionFormat === 'Photo' && jpegString) &&
          <Button
            onClick={() => validate('confirm')}
            text={`Save with note`}
            // disabled={isAnswerPending ? false : true}
            // variant={questionAnswerValue ? 'primary' : 'gray'}
          // size={'big'}
          />}

        {
          isAnswerPending && 
          !showAnswerConfirmation &&
          !isQuestionLocked &&
          (thisQuestion?.QuestionFormat !== 'Photo' || thisQuestion?.QuestionFormat === 'Photo' && jpegString) &&
          <Button
            onClick={() => validate(null)}
            text={`Save`}
            // disabled={questionAnswerValue ? false : true}
            // variant={questionAnswerValue ? 'primary' : 'gray'}
          // size={'big'}
          />}

        {!isAnswerPending && nextStepUrl && <Button
          internalLinkUrl={nextStepUrl}
          text={`Next`}
          disabled={false}
          variant={`primary`}
        // size={'big'}
        />}


      </div>
    </div>

  </div>
}
export default QuestionPage