import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "../GlobalContext"
import Card from "../ui-elements/Card"
import RedAlert from "../ui-elements/RedAlert"
import YellowAlert from "../ui-elements/YellowAlert"
import { getDate } from "../utils/getDate"
import FormatQuestionAnswer from "./FormatQuestionAnswer"
import CloudformationOutputs from '../CloudformationOutputs.json'
import FormatQuestionAnsweredBy from "./FormatQuestionAnsweredBy"
import { findNextQuestionUrl } from "../utils/findNextQuestionUrl"
import { checkIfAnswerIsLocked } from "../utils/checkIfAnswerIsLocked"
import AddButton from "../formfields/AddButton"
import Button from "../formfields/Button"
import FormatFridgeName from "./FormatFridgeName"

type Props = {
  questionAnswer: ObjectStringKeyAnyValue
  checkPeriodId: string | undefined
  question: ObjectStringKeyAnyValue
  sortedQuestionsAndAnswers: Array<ObjectStringKeyAnyValue>
  answersForThisQuestion: Array<ObjectStringKeyAnyValue>
  answerNumber: number,
  parentQuestion: ObjectStringKeyAnyValue | null
}

function QuestionSummaryDetail({
  questionAnswer,
  checkPeriodId,
  question,
  sortedQuestionsAndAnswers,
  answersForThisQuestion,
  answerNumber,
  parentQuestion
}: Props) {

  const {
    tableData,
    currentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)
  const navigate = useNavigate()

  const [isQuestionLocked, setIsQuestionLocked] = useState(false)
  const [lockedUntilText, setLockedUntilText] = useState('')
  const numberOfAlerts = questionAnswer && questionAnswer['AnswerAlerts'] ? Object.values(questionAnswer['AnswerAlerts']).filter((alert: any) => alert['actionToTake'] !== 'followUpQuestion').length : null

  useEffect(() => {
    checkIfAnswerIsLocked(questionAnswer, setIsQuestionLocked, setLockedUntilText)
    const intervalId = setInterval(() => {
      checkIfAnswerIsLocked(questionAnswer, setIsQuestionLocked, setLockedUntilText)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  if (!question) return null

  return <div className={`w-full flex flex-row items-start`}>

    {(parentQuestion || (questionAnswer && questionAnswer.repeatAnswerAfter)) &&
    <div className={`w-12 border-b-4 border-dotted border-white mt-2 mr-2`}></div>
    }

    <div
      className={`text-left w-full rounded bg-white flex flex-row 
        ${isQuestionLocked ? ` opacity-60 ` : ` hover:opacity-80 cursor-pointer `}
        `}
      onClick={() => {
        if (!isQuestionLocked && questionAnswer.Pending) {
          navigate(`/checklists/${checkPeriodId}/${questionAnswer.Id}`)
        }
        else if (!isQuestionLocked && !questionAnswer.Pending) {
          navigate(`/checklists/${checkPeriodId}/confirm/${questionAnswer.Id}`)
        }
      }}
    >






      {questionAnswer && !isQuestionLocked && !questionAnswer.Pending &&
        <div className={`w-16 bg-brandblue text-white rounded-l py-4 px-2 flex flex-col justify-center items-center text-2xl font-bold`}>✓</div>}

      {questionAnswer && !isQuestionLocked && questionAnswer.Pending &&
        <div className={`w-16 bg-red-400 text-white rounded-l py-4 px-2 flex flex-col justify-center items-center text-2xl font-bold`}>?</div>}

      {questionAnswer && isQuestionLocked &&
        <div className={`w-16 bg-gray-600 text-white rounded-l py-4 px-2 flex flex-col justify-center items-center text-2xl font-bold`}>🔐</div>}


      <div className={`w-full flex flex-row justify-between items-center`}>

        <div className={`w-full p-2 text-sm flex flex-col justify-center items-start gap-1`}>

          {/* <div className={`text-xs`}>
            <p>This answer: {questionAnswer.Id}</p>
            <p>Next answer: {findNextQuestion(questionAnswer.Id, sortedQuestionsAndAnswers)}</p>
          </div> */}




          {question.QuestionFormat !== 'FridgeTemperature' && <h5 className={`text-lg font-righteous text-brandblue`}>{question.QuestionText}</h5>}
          {question.QuestionFormat === 'FridgeTemperature' && <h5 className={`text-lg font-righteous text-brandblue`}>Temperature check for 
            
          <FormatFridgeName fridgeId={question.FridgeId} />
              </h5>}

          {parentQuestion && <p className={`text-xs italic`}>Followup question for: "{parentQuestion.QuestionText}"</p>}


          <p className={`text-xs text-gray-600 italic`}>
            {answersForThisQuestion.length > 1 && `🔁 Answer ${answerNumber} of ${answersForThisQuestion.length}. `}

            {questionAnswer &&
              questionAnswer.repeatAnswerAfter &&
              questionAnswer.repeatAnswerAfter.type === 'timeDelay' && `Repeated after ${questionAnswer.repeatAnswerAfter.number < 60 ? ` ${questionAnswer.repeatAnswerAfter.number} minutes ` : ` ${Math.round(questionAnswer.repeatAnswerAfter.number / 60 * 10) / 10} hours `}.`}


            {questionAnswer &&
              questionAnswer.repeatAnswerAfter &&
              questionAnswer.repeatAnswerAfter.type === 'numberOfTimes' && ` Repeated after ${questionAnswer.repeatAnswerAfter.number} time${questionAnswer.repeatAnswerAfter.number > 1 ? 's' : ''}.`}

          </p>


          {lockedUntilText && <p className={`text-xs text-gray-600 italic`}>Question locked. Answer in: {lockedUntilText}</p>}




          {!questionAnswer && question.QuestionInstructions && <p className={`text-xs text-gray-600 italic`}><span className={`font-bold`}>Instructions</span>: {question.QuestionInstructions} </p>}

          {questionAnswer.Pending && <p className={``}>Not yet answered</p>}


          {questionAnswer && !questionAnswer.Pending && <div className={`w-full flex flex-col items-start text-left gap-1`}>


            <p className={``}>
              <FormatQuestionAnswer
                questionAnswer={questionAnswer}
                isTemperature={question.QuestionFormat === 'FridgeTemperature' ? true : false}
              />
            </p>

            <p className={`text-xs text-gray-600 italic`}>
              <span className={`font-bold`}>Answered by</span>:
              <FormatQuestionAnsweredBy
                questionAnswer={questionAnswer}
              />
            </p>


            <div className={`w-full flex flex-row gap-2 items-start justify-start`}>
              {numberOfAlerts ?
                <RedAlert
                  icon={true}
                  fullwidth={false}
                  alignment={`left`}
                  size={`small`}
                >
                  {numberOfAlerts} alert{numberOfAlerts > 1 ? 's' : ''}
                </RedAlert> : ''}


              {questionAnswer.AnswerNotes && Object.keys(questionAnswer.AnswerNotes).length > 0 &&
                <YellowAlert
                  icon={true}
                  fullwidth={false}
                  alignment={`left`}
                  size={`small`}
                >
                  {Object.keys(questionAnswer.AnswerNotes).length} user note{Object.keys(questionAnswer.AnswerNotes).length > 1 ? 's' : ''} added
                </YellowAlert>}

            </div>

          </div>}







          {/* <div className={`text-xs text-gray-600 italic`}>
        <p>{question.Id}</p>
        <p>{nextQuestionId}</p>
      </div> */}

        </div>

        {questionAnswer && questionAnswer.ImageFileName && currentOrganisation &&
          <img src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${currentOrganisation}/${questionAnswer.ImageFileName}`} alt=''
            className={`rounded bg-gray-400 min-h-8 min-w-8 max-h-20 m-2`}
          />}
      </div>


      {/* <div className={`p-2 flex flex-col gap-1`}>
      <Button
        internalLinkUrl={`/checklists/${checkPeriodId}/${questionAnswer.Id}`}
        text={`Answer`}
        variant={`primary`}
        size={`small`}
      />
      <AddButton
        onClick={() => { }}
        text={`Text note`}
      />
      <AddButton
        onClick={() => { }}
        text={`Photo note`}
      />

    </div> */}


    </div>
  </div>


}


export default QuestionSummaryDetail