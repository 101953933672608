
import React, { useContext, useRef, useEffect, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'
import { useVideo } from '../context/video/VideoContext';
import RedAlert from '../ui-elements/RedAlert'
import PhotoPreview from './PhotoPreview'
import PhotoNavigation from './PhotoNavigation'
import FridgeReadingDetected from './FridgeReadingDetected'
import { checkIfAssetExists } from '../utils/checkIfAssetExists'
import { getAssetIdFromUrl } from '../utils/getAssetIdFromUrl'
import YellowAlert from '../ui-elements/YellowAlert'
import Modal from '../ui-elements/Modal'
import { concat } from '@tensorflow/tfjs'
import Card from '../ui-elements/Card'
import { getAnswersForThisAssetId } from '../utils/getAnswersForThisAssetId'



type Props = {
  questionId?: string
  checklistId?: string
  questionAnswer: string | null | string[]
  setQuestionAnswer: React.Dispatch<React.SetStateAction<any>>
  defaultValue: null | string | string[]
  fridgeId: string | null
  answerNote: string | null
  setAnswerNote: React.Dispatch<React.SetStateAction<any>>
  answerPhoto: string | null
  setAnswerPhoto: React.Dispatch<React.SetStateAction<any>>
  isAnswerManuallyEdited: boolean
  setIsAnswerManuallyEdited: React.Dispatch<React.SetStateAction<any>>
  jpegString: string | null
  setJpegString: React.Dispatch<React.SetStateAction<any>>
  fridgeHasDigitalDisplay: boolean | null
  checkPeriodId?: string
}

function QuestionFridgeTemperature({
  checkPeriodId,
  questionId,
  checklistId,
  questionAnswer,
  setQuestionAnswer,
  defaultValue,
  fridgeId,
  answerNote,
  setAnswerNote,
  answerPhoto,
  setAnswerPhoto,
  isAnswerManuallyEdited,
  setIsAnswerManuallyEdited,
  jpegString,
  setJpegString,
  fridgeHasDigitalDisplay
}: Props) {

  const {
    tableData,
    isVideoMode,
    setIsVideoMode,
    isVideoPlaying,
    setIsVideoPlaying
  } = useContext(GlobalContext)


  const navigate = useNavigate()
  // const [jpegString, setJpegString] = useState<string | undefined>(undefined)
  const [showReadingDetectedModal, setShowReadingDetectedModal] = useState(false)
  const [showInvalidAssetMessage, setShowInvalidAssetMessage] = useState(false)
  const [showUnexpectedQrCodeModal, setShowUnexpectedQrCodeModal] = useState(false)
  const [showAssetAlreadyChecked, setShowAssetAlreadyChecked] = useState<string | null>(null)
  const { QRCodesPresent, setQRCodesPresent, setQRCodesSearchOptions, captureAsJPEGString, setExpectedReadingDetails, aiResults, clearCameraStateVariables } = useVideo()
  const defaultQrSearchOptions = {
    'enabled': true,
    'expectedDomains': ['QRWL.ME', 'CHQN.IN'],
    'searchArea': { top: 0, left: 0, width: 1, height: 1 },
    'showSearchArea': false,
    'takeStraightenedPhoto': true,
    'attemptAiReading': true
  }


  useEffect(() => {
    return () => {
      // code to run when the user navigates away from the page
      setIsVideoMode(false)
      setIsVideoPlaying(false)
      // console.log("leaving page")
    }
  }, [])


  useEffect(() => {
    if (showAssetAlreadyChecked){
      const timeoutId = setTimeout(() => {
        setShowAssetAlreadyChecked(null)
      } , 2000)
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [showAssetAlreadyChecked])


  useEffect(() => {
    if (fridgeId && tableData) {
      // check if asset exists
      if (tableData && tableData['Assets'][fridgeId]) {
        const asset = tableData['Assets'][fridgeId]
        if (asset['DigitsAfterDecimal'] && asset['DigitsAfterDecimal']) {
          console.log(`setting expected reading details. Before: ${asset["DigitsBeforeDecimal"]} after: ${asset["DigitsAfterDecimal"]}`)
          setExpectedReadingDetails(parseInt(asset['DigitsAfterDecimal']), 10)
        } else {
          //console.log(`No expected reading details found for asset ${fridgeId}`)
        }
      }
    }
  }, [fridgeId])





  useEffect(() => {
    let isFridgeIdValid = false
    let doesAssetMatch = null
    let letHasQrBeenFound = false
    let assetIdFromDataUrl = null

    if (fridgeId && tableData) {
      // check if asset exists
      if (tableData && checkIfAssetExists(fridgeId, tableData)) {
        isFridgeIdValid = true
      }
      // check if asset from QR code matches asset associated with this question
      if (QRCodesPresent && QRCodesPresent.length > 0) {
        assetIdFromDataUrl = getAssetIdFromUrl(QRCodesPresent[0])
        letHasQrBeenFound = true
        doesAssetMatch = assetIdFromDataUrl === fridgeId
      } else {
        letHasQrBeenFound = false
        doesAssetMatch = false
      }


      if (isFridgeIdValid) {
        // if (fridgeHasDigitalDisplay) {
        // start the camera if the id is valid and has a digital readout,
        // but no qr code has been detected yet

        setIsVideoMode(true)
        setIsVideoPlaying(true)
        setQRCodesSearchOptions(defaultQrSearchOptions)

        if (letHasQrBeenFound && doesAssetMatch === true) {
          // if a QR is detected and it matches the asset,
          // show the reading detected screen
          capture()
          setShowReadingDetectedModal(true)
        } else if (letHasQrBeenFound && doesAssetMatch === false) {

          const assetIds: ObjectStringKeyAnyValue = getAnswersForThisAssetId(tableData, checkPeriodId)
          const answerForScannedAsset = assetIds && assetIds[assetIdFromDataUrl]

          if (answerForScannedAsset && answerForScannedAsset.answerId) {
            if (answerForScannedAsset['pending'] === true) {
              // if it an asset that hasn't been checked yet,
              // redirect to that question
              navigate(`/checklists/${checkPeriodId}/${answerForScannedAsset.answerId}`)
            } else {
              // if it an asset that has been checked already 
              setShowAssetAlreadyChecked(assetIdFromDataUrl)
              setShowUnexpectedQrCodeModal(false)
            }
          } else {
            // if it not part of this checklist at all, show a warning
            setShowUnexpectedQrCodeModal(true)
            setShowAssetAlreadyChecked(null)
          }

        } else {
          setShowReadingDetectedModal(false)
        }
        // }
        // else {
        // allow a manual reading to be entered if it has no digital display
        // setShowReadingDetectedModal(true)
        // setIsVideoMode(false)
        // setIsVideoPlaying(false)
        // }
      } else {
        // if asset is invalid, show warning message
        setShowInvalidAssetMessage(true)
        setIsVideoMode(false)
        setIsVideoPlaying(false)
      }
    }
    // if (!QRCodesPresent) {
    //   setShowReadingDetectedModal(false)
    // }

  }, [QRCodesPresent, tableData, fridgeId])







  const capture = () => {
    const jpgData = captureAsJPEGString(600)
    setAnswerPhoto && setAnswerPhoto(jpgData)
    setJpegString && setJpegString(jpgData)
  }

  const retakePhoto = () => {
    //setShowReadingDetectedModal(false)
    setQRCodesPresent(null)
    setIsVideoMode(true)
    setIsVideoPlaying(true)
    setQRCodesSearchOptions(defaultQrSearchOptions)
    clearCameraStateVariables()
  }





  return <div
    className={`max-w-3xl w-full pt-0 pb-8 px-3 flex flex-col gap-2 justify-center items-center`}
  >



    {!fridgeId && <RedAlert fullwidth={true}>No fridge Id</RedAlert>}
    {showInvalidAssetMessage && <RedAlert fullwidth={true}>Fridge ID #{fridgeId} is invalid</RedAlert>}




    {showAssetAlreadyChecked && <Modal>
      <div className={`flex flex-col gap-4`}>
        <div className={`flex flex-col gap-2 items-center`}>

          <img src={`/icons/green-tick-icon.png`} alt={'Green tick icon'} className={`w-8 h-8`} />
          <h4 className={`font-righteous text-xl text-brandblue`}>Asset #{showAssetAlreadyChecked} has already been checked</h4>
        </div>
        <Button
          text={'Ok'}
          onClick={() => setShowAssetAlreadyChecked(null)}
          size={'big'}
          fullwidth={true}
          variant={'primary'}
        />
      </div>
    </Modal>}


    {showUnexpectedQrCodeModal && <Modal>
      <div className={`flex flex-col gap-4`}>
        <div className={`flex flex-col gap-2`}>
          <h4 className={`font-righteous text-xl text-brandblue`}>Unexpected QR code</h4>
          {fridgeId && <p>Expecting QR code for fridge Id: #{fridgeId}</p>}
        </div>
        <Button
          text={'Retry'}
          onClick={() => setShowUnexpectedQrCodeModal(false)}
          size={'big'}
          fullwidth={true}
          variant={'primary'}
        />
      </div>
    </Modal>}




    {showReadingDetectedModal &&
      <FridgeReadingDetected
        jpegString={jpegString}
        setShowReadingDetectedModal={setShowReadingDetectedModal}
        // detectedTemperatureReading={null}
        questionAnswer={questionAnswer}
        setQuestionAnswer={setQuestionAnswer}
        answerNote={answerNote}
        setAnswerNote={setAnswerNote}
        answerPhoto={answerPhoto}
        setAnswerPhoto={setAnswerPhoto}
        digitalDisplay={fridgeHasDigitalDisplay || false}
        retakePhoto={retakePhoto}
        aiResults={aiResults}
        fridgeId={fridgeId}
        isAnswerManuallyEdited={isAnswerManuallyEdited}
        setIsAnswerManuallyEdited={setIsAnswerManuallyEdited}
      />}


  </div>
}
export default QuestionFridgeTemperature